<template>
  <div id="mobile" @click="hideQr">
    <div class="container">
      <div class="page-bg"></div>
      <div class="header" :class="{scroll:isScroll}">
        <div class="lPart">
          <img src="./logo.png" alt="" class="logo">
        </div>
        <div class="rPart" @click="qrPopupShow">
          <img src="./qr.png" alt="">
        </div>
      </div>
      <div class="qrJump" v-show="qrJump" @click="showQrjump">
        <img src="./qr-gzh.png" alt="">
      </div>
      <div class="topBanner">
        <div class="topBannerBox" ref="topBannerBox">
          <div class="slogan ">
            让全球每一位医生 <br />
            都能用上医学影像
          </div>
        </div>
      </div>
      <div class="infoPart wow fadeInUp" data-wow-duration=".8s">
        <!-- 身边即享超声服务 -->
        <section class="blc blc2 wow fadeInUp" data-wow-duration=".8s">
          <div class="content">
            <div class="p-title wow fadeInUp" data-wow-delay=".5s">
              <h3>身边即享超声服务</h3>
              <h4>Senz technology</h4>
            </div>
            <div id="lottieWrap">
              <div id="lottie"></div>
            </div>
            <div class="tip wow fadeInUp" data-wow-delay=".7s">
              <h4>2分钟检查过程</h4>
              <h4>10分钟获得报告</h4>
            </div>
            <button class="wow fadeInUp" data-wow-delay=".8s" @click="qrPopupShow">立即联系我们</button>
            <p class="wow fadeInUp" data-wow-delay=".9s">简单易用的操作方式，引导非超声医师2分钟完成检查；远端专业医师，快速响应，10分钟出具专业报告</p>
          </div>
        </section>
        <!-- 我们的使命 -->
        <section class="blc blc3 wow fadeInUp" data-wow-duration=".8s">
          <div class="content">
            <div class="p-title wow fadeInUp" data-wow-delay=".5s">
              <h3>我们的使命</h3>
              <h4>打造基层影像家园</h4>
            </div>
            <img src="./doctor.png" alt="" class="wow fadeInUp" data-wow-delay=".6s">
            <p class="wow fadeInUp" data-wow-delay=".7s">无场地要求、无影像科要求、赋能基层诊所，共创基层影像家园</p>
            <a @click.stop="qrPopupShow" class="wow fadeInUp" data-wow-delay=".7s">进一步了解我们</a>
          </div>
        </section>
        <!-- 软件功能介绍 -->
        <section class="blc blc5 wow fadeInUp" data-wow-duration=".8s">
          <div class="content">
            <div class="p-title wow fadeInUp" data-wow-delay=".5s">
              <h3>软件功能介绍</h3>
              <h4>Multiple image modes</h4>
            </div>
            <van-swipe class="software-swipe wow fadeInUp" :autoplay='false' :duration='800' indicator-color="#2779FF" data-wow-delay=".6s" @change="onChangeSwipe2">
              <van-swipe-item>
                <img src="./software1.png" alt="">
                <div class="info">
                  <h3>
                    适用范围广泛
                  </h3>
                  <p>腹部、血管、浅表、泌尿、妇科</p>
                </div>
              </van-swipe-item>
              <van-swipe-item>
                <img src="./software2.png" alt="">
                <div class="info">
                  <h3>
                    智能导航
                  </h3>
                  <p>部位识别、方位识别、病理识别</p>
                </div>
              </van-swipe-item>
              <van-swipe-item>
                <img src="./software3.png" alt="">
                <div class="info">
                  <h3>
                    多种图像模式
                  </h3>
                  <p>灰阶模式、彩阶模式、频谱模式</p>
                </div>
              </van-swipe-item>
              <template #indicator>
                <ul class="spots">
                  <li :class="[swipe2Current== 0? 'highlight': '']"></li>
                  <li :class="[swipe2Current== 1? 'highlight': '']"></li>
                  <li :class="[swipe2Current== 2? 'highlight': '']"></li>
                </ul>
              </template>
            </van-swipe>
          </div>
        </section>
        <!-- 权威认证 -->
        <section class="blc blc6 wow fadeInUp" data-wow-duration=".8s">
          <div class="content">
            <div class="p-title wow fadeInUp" data-wow-delay=".5s">
              <h3>权威认证</h3>
              <h4>About out products</h4>
            </div>
            <img src="./cert.gif" class="cert" alt="GIF Image">
          </div>
        </section>

        <!-- 我们期待您的加入 -->
        <section class="blc contact wow fadeInUp" data-wow-duration=".8s">
          <div class="content">
            <div class="p-title wow fadeInUp" data-wow-delay=".5s">
              <h3>我们期待您的加入！</h3>
              <h4>扫描二维码访问Senz公众号</h4>
            </div>
            <div class="footer">
              <img src="./qr-gzh.png" alt="" class="qr">
              <div class="remark">
                <a href="https://beian.miit.gov.cn/">{{record}}</a>
                <span>Copyright © 2018-2024 版权所有</span>
                <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502017406/">沪公网安备 31011502017406号</a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div class="loading" v-show="loadingShow"></div>
    <van-popup v-model="showPopup" class="popupQr">
      <img src="./qr-gzh.png" alt="" class="qr">
      <img src="./close.png" alt="" class="close" @click='qrPopupClose'>
    </van-popup>
  </div>
</template>
<script>
import { WOW } from 'wowjs'
import animationData from '../../../public/data.json'
export default {
  data() {
    return {
      isScroll: false,
      qrJump: false,
      showPopup: false,
      loadingShow: true,
      // 第一个swipe的索引
      swipe1Current: 0,
      // 第二个swipe的索引
      swipe2Current: 0,
      record: '沪ICP备19048066号-4'
    }
  },
  created() {
    window.addEventListener('scroll', this.scrollHandle);
    const host = window.location.host
    if(host.indexOf('senzco.com') !== -1){
      this.record = '沪ICP备19048066号-9'
    }
  },
  mounted() {
    // window.addEventListener('click', this.hideQr);
    this.$nextTick(() => {
      let wow = new WOW({
        offset: 0,
        live: false
      })
      this.loadingShow = false
      wow.init()
    })
    console.log(document)
    /* eslint-disable */
    var params = {
        container: document.getElementById('lottie'),
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: animationData
    };

    var anim;

    anim = lottie.loadAnimation(params);
    /* eslint-enable */
  },
  methods: {
    scrollHandle() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      const rollNum = 400
      let rollMath = 1-(scrollTop/rollNum)
      this.$refs.topBannerBox.style.opacity = rollMath
      // this.$refs.adImg.style.opacity = rollMath
      if (scrollTop > 10 ) {
        this.isScroll = true
      } else {
        this.isScroll = false
      }
    },
    hideQr() {
      if(this.qrJump) {
        this.qrJump = false
      }
    },
    showQrjump () {
      this.qrJump = !this.qrJump
    },
    qrPopupShow() {
      this.showPopup = true
    },
    qrPopupClose() {
      this.showPopup = false
    },
    // 第一个swipe
    onChangeSwipe1(index) {
      this.swipe1Current = index;
    },
    // 第二个swipe
    onChangeSwipe2(index) {
      this.swipe2Current = index;
    },
  }
}
</script>
<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
  .container {
    background-color: #010D1A;
    font-family: PingFangSC-Medium, PingFang SC;
    .page-bg {
      position: fixed;
      z-index: -1;
      background: #010D1A;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    .header {
      height: 94px;
      line-height: 94px;
      background: transparent;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 999;
      display: flex;
      justify-content: space-between;
      transition: all .3s;
      &.scroll {
        background-color: #010D1A;
      }
      .lPart {
        display: flex;
        align-items: center;
        margin-left: 32px;
        .logo {
          width: 100px;
          height: 27px;
          margin-right: 10px;
        }
      }
      .rPart {
        margin-right: 24px;
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
    .qrJump {
      width: 100px;
      height: 100px;
      position: fixed;
      top: 60px;
      right: 24px;
      z-index: 999;
      border-radius: 3px;
      overflow: hidden;
      img {
        width: 100px;
        height: 100px;
      }
    }
    .slogan {
      margin: 126px auto 0;
      widows: 100%;
      font-weight: 600;
      font-size: 38px;
      color: #FFFFFF;
      line-height: 51px;
      text-align: center;
    }
    .topBanner {
      width: 414px;
      // height: 786px;
      // background: url('./topBg.png') no-repeat top center ;
      background-color: #010D1A;
      background-size: 100%;
      position: fixed;
      z-index: 1;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-size: cover;

      .topBannerBox {
        background: url('./topBg.png') no-repeat top center ;
        background-size: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 11;

        .tool {
          width: 316px;
          height: 555px;
          // background: url('./tool.png') no-repeat right center;
          // background-size: 316px 555px;
          position: absolute;
          top: 143px;
          right: 0;
          // background-attachment: fixed;
          &:before {
            content: ' ';
            position: fixed;
            z-index: 0;
            top: 143px;
            right: 0;
            bottom: 0;
            left: 98px;
            background: url('./tools.png') center 0 no-repeat;
            // background-size: cover;
            background-size: 316px 555px;
          }
        }
        .adImg {
          color: #fff;
          position: fixed;
          top: 462px;
          left: 32px;
          z-index: 0;
          img {
            width: 32px;
            height: 14px;
          }
          p {
            font-size: 12.5px;
            line-height: 20px;
          }
        }
        .ad {
          color: #fff;
          position: fixed;
          top: 462px;
          left: 32px;
          z-index: 0;
          img {
            width: 32px;
            height: 14px;
          }
          p {
            font-size: 12.5px;
            line-height: 20px;
          }
        }
      }
    }
    .infoPart {
      // display: none;
      position: relative;
      z-index: 9;
      // background-color: #010D1A;
      width: 394px;
      margin: 577px auto 0;
      padding-bottom: 35px;
      .blc {
        position: relative;
        z-index: 1;
        box-sizing: border-box;
        // padding: 30px 27px 27px 30px;
        margin: 0px auto 10px;
        width: 394px;
        background-color: #19202C;
        border-radius: 30px;
        overflow: hidden;
        .p-title {
          padding: 30px;
          h3 {
            color: #fff;
            font-size: 24px;
            line-height: 33px;
          }
          h4 {
            color: #666;
            font-size: 14px;
            line-height: 20px;
          }
        }

        &.blc2 {
          padding-bottom: 17px;
          margin: 0px auto 10px;
          width: 394px;
          background-color: #19202C;
          border-radius: 30px;
          .p-title {
            padding: 30px 30px 0;
            margin-bottom: 25px;
          }
          #lottieWrap {
            width: 345px;
            height: 364px;
            margin: 0 auto 25px;
            #lottie{
              background-color:#000000;
              width:100%;
              height:100%;
              display:block;
              overflow: hidden;
              transform: translate3d(0,0,0);
              text-align: center;
              opacity: 1;
            }
          }
          img {
            display: block;
            width: 334px;
            height: 260px;
            margin: 0 auto 25px;
            // vertical-align: top;
          }
          .tip {
            font-size: 18px;
            line-height: 25px;
            color: #fff;
            margin-bottom: 19px;
            padding-left: 30px;
          }
          button {
            width: 120px;
            height: 32px;
            line-height: 32px;
            font-size: 14px;
            color: #fff;
            background: #2779FF;
            border: 0 none;
            border-radius: 2px;
            margin-left: 30px;
          }
          p {
            width: 334px;
            font-size: 14px;
            color: #fff;
            line-height: 22px;
            margin-top: 20px;
            margin-left: 30px;
          }
        }
        &.blc3 {
          padding-bottom: 28px;
          margin: 0px auto 10px;
          width: 394px;
          background-color: #19202C;
          border-radius: 30px;
          .p-title {
            padding: 30px;
            h3 {
              font-size: 16px;
              color: #FFFFFF;
              line-height: 22px;
              margin-bottom: 8px;
            }
            h4 {
              font-size: 24px;
              font-weight: 500;
              color: #FFFFFF;
              line-height: 33px;
            }
          }
          img {
            width: 334px;
            margin: 0 auto;
            display: block;
          }
          p {
            width: 310px;
            font-size: 14px;
            line-height: 22px;
            color: #fff;
            margin-top: 23px;
            margin-bottom: 10px;
            padding-left: 30px;
          }
          a {
            display: inline-block;
            color: #2779FF;
            font-size: 14px;
            height: 20px;
            border-bottom: 1px solid #2779FF;
            margin-left: 30px;
          }
        }

        &.blc5 {
          padding-bottom: 30px;
          .p-title {
            padding-bottom: 0;
          }
          .spots {
            text-align: center;
            height: 6px;
            transition: all .6s;
            margin-top: 13px;
            li {
              display: inline-block;
              vertical-align: top;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background: #D8D8D8;
              margin: 0 3px;
              &.highlight {
                width: 18px;
                height: 6px;
                border-radius: 5px;
                background-color: #2779FF;
              }
            }
          }
          .software-swipe {
            text-align: center;
            img {
              width: 289px;
              height: 491px;
            }
            .info {
              margin-top: -25px;
              h3 {
                color: #fff;
                font-size: 18.09px;
                line-height: 25px;
                font-weight: 500;
                margin-bottom: 6px;
              }
              p {
                font-size: 14.89px;
                line-height: 21px;
                color: #fff;
                font-weight: 400x;
              }
            }
          }
        }
        &.blc6 {
          padding-bottom: 44px;
          .cert {
            display: flex;
            margin: 0 auto;
            width: 357px;
            height: 256px;
          }
        }
        &.contact {
          width: 393px;
          height: 463px;
          background: url('./footer.png') no-repeat center center;
          background-size: cover;
          .content {
            display: flex;
            flex-flow: column;
            justify-content: space-between;
            width: 100%;
            height: 100%;
            padding-bottom: 30px;
          }
          h4 {
            color: #fff;
          }
          .footer {
            display: flex;
            align-items: flex-end;
            padding-left: 30px;
            .qr {
              width: 100px;
              height: 100px;
              border-radius: 3px;
            }
            .remark {
              margin-left: 25px;
              width: 100%;
              a, span {
                display: block;
                width: 100%;
                font-size: 14px;
                color: #FFDDB9;
                line-height: 19px;
              }
            }
          }


        }
      }
    }
  }
  .loading {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #000;
    z-index: 999;
  }
  .popupQr {
    background: transparent;
    top: 40%;
    .qr {
      width: 180px;
      height: 180px;
      display: block;
      margin: 0 auto 30px;
      border-radius: 3px;
    }
    .close {
      width: 36px;
      height: 36px;
      display: block;
      margin: 0 auto;
    }
  }
</style>
